import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FONT_GOTHAM_LIGHT } from '../styles/fonts';

import Spinner from './Spinner';

interface LoadingBlockerProps {
    isLoading: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    errorMessage?: string;
    successMessage?: string;
}

const LoadingBlocker: React.FC<LoadingBlockerProps> = ({
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    successMessage,
}) => {
    return (
        <LoadingContainer isVisible={isLoading || isSuccess || isError}>
            {(isSuccess || isError) && (
                <TextContainer>
                    {isSuccess && successMessage}
                    {isError && errorMessage}
                </TextContainer>
            )}
            {isLoading && <Spinner />}
        </LoadingContainer>
    );
};

const TextContainer = styled.div`
    font-size: 18px;
    font-family: ${FONT_GOTHAM_LIGHT};
    color: #2e0f25;
    margin-bottom: 24px;
    text-align: center;
`;

const LoadingContainer = styled('div')<{ isVisible: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.8);

    ${(props) =>
        props.isVisible &&
        css`
            opacity: 1;
            visibility: visible;
        `};
`;

export default LoadingBlocker;
