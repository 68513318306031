import React, { useState, HTMLProps, useCallback } from 'react';
import { StickyContainer } from 'react-sticky';
import styled from '@emotion/styled';
import { SWRConfig } from 'swr';

import { defaultSWRConfigs } from '../constants';
import { Seo } from '../models';

import HelmetComponent from './Helmet';
import { ScrollUpButton } from './ScrollUpButton';
import { AppContextProvider } from './AppContext';
import { MobileMenu } from './MobileMenu/MobileMenu';
import StayModal from './StayModal';

import '../styles/index.scss';
import 'regenerator-runtime/runtime';

interface LayoutProps {
    seoMeta: Seo;
    contentProps?: HTMLProps<'div'>;
}

const Layout: React.FC<LayoutProps> = ({
    children,
    seoMeta,
    contentProps = {},
}) => {
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const toggleMobileMenu = () => setIsMobileMenuOpened(!isMobileMenuOpened);

    const onContextMenu = useCallback((event) => {
        event.preventDefault();

        return false;
    }, []);

    return (
        <>
            <AppContextProvider
                isMobileMenuOpened={isMobileMenuOpened}
                toggleMobileMenu={toggleMobileMenu}
            >
                <SWRConfig value={defaultSWRConfigs}>
                    <StickyContainer>
                        <HelmetComponent seoMeta={seoMeta} />
                        <Content
                            {...(contentProps as any)}
                            id="main-container"
                            isMobileMenuOpened={isMobileMenuOpened}
                            onContextMenu={onContextMenu}
                        >
                            {children}
                            <MobileMenu />
                            <StayModal />
                        </Content>
                        <ScrollUpButton showAfter={700} duration={500} />
                    </StickyContainer>
                </SWRConfig>
            </AppContextProvider>
        </>
    );
};

const Content = styled('div')<{ isMobileMenuOpened: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    user-select: none;
`;

export default Layout;
