import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Sticky } from 'react-sticky';
import { Link } from 'gatsby';

import { device } from '../commonStyled';
import { AppContext } from '../AppContext';
import logo from '../../images/logo.png';
import { BG_COLORS, HeaderBgColor, DEFAULT_ALT } from '../../constants';
import { ReactComponent as SearchIcon } from '../../images/search-icon.svg';
import { Flex } from '../Box';

import { BurgerIcon } from './BurgerIcon';

interface MobileMenuHeaderProps {
    bgColor?: HeaderBgColor;
    handleSearchClick(): void;
}

const MobileMenuHeader: React.FC<MobileMenuHeaderProps> = ({
    bgColor,
    handleSearchClick,
}) => {
    const {
        isMobileMenuOpened: isOpened,
        toggleMobileMenu: toggleMenu,
    } = useContext(AppContext);

    return (
        <>
            <Sticky topOffset={20}>
                {({ style, isSticky }) => (
                    <NavBar isSticky={isSticky} bgColor={bgColor} style={style}>
                        <BurgerIcon
                            isOpened={isOpened}
                            toggleMenu={toggleMenu}
                        />
                        <SearchIconButton
                            title="поиск по сайту"
                            onClick={handleSearchClick}
                        >
                            <SearchIconStyled />
                        </SearchIconButton>
                        <Flex flexGrow={1} />
                        <Link to="/">
                            <Logo
                                src={logo}
                                isSticky={isSticky}
                                alt={DEFAULT_ALT}
                            />
                        </Link>
                    </NavBar>
                )}
            </Sticky>
        </>
    );
};

export default MobileMenuHeader;

const SearchIconButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`;

const SearchIconStyled = styled(SearchIcon)`
    width: 25px;
    height: auto;
    fill: #dcb972;
`;

const Logo = styled('img')<{ isSticky: boolean }>`
    width: 48px;

    transform: ${({ isSticky }) =>
        isSticky ? 'scale(0.8) translateX(-3px)' : 'scale(1)'};
    transition: all 0.2s ease-in-out;
`;

const NavBar = styled('div')<{ bgColor?: HeaderBgColor; isSticky: boolean }>`
    display: none;
    position: relative;
    height: 60px;
    align-items: center;
    padding: 0 24px 0 12px;
    z-index: 9998;
    background-color: ${({ bgColor }) =>
        bgColor ? BG_COLORS[bgColor] : '#fff'};
    transition: shadow 0.2s ease-in-out;

    ${({ isSticky }) =>
        isSticky &&
        css`
            box-shadow: 0px 7px 15px #ccc;
        `}

    @media ${device.laptopMedium} {
        display: flex;
    }
`;
