import { useState, useEffect } from 'react';

import { isWebpSupported } from '../utils';

export const useWebpImage = (webp: string, png: string) => {
    const [image, setImage] = useState(webp);

    useEffect(() => {
        if (!isWebpSupported()) {
            setImage(png);
        }
    }, []);

    return image;
};
