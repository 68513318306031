import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const Spinner: React.FC<{ className?: string }> = ({ className }) => {
    return <Loader className={className} />;
};

const spin = keyframes`
    0% {      
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    display: block;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #a60424;
    animation: ${spin} 2s linear infinite;

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #950420;
        animation: ${spin} 3s linear infinite;
    }

    &:after {
        content: '';
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #85031d;
        animation: ${spin} 1.5s linear infinite;
    }
`;

export default Spinner;
