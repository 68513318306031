import React, { CSSProperties } from 'react';
import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image';

import { DEFAULT_ALT } from '../constants';

interface CompatibleImgProps {
    alt?: string;
    image: any;
    className?: string;
    imageStyle?: CSSProperties;
}

export const CompatibleImg: React.FC<CompatibleImgProps &
    Partial<GatsbyImageProps>> = ({
    alt = DEFAULT_ALT,
    image: imageRaw,
    imageStyle,
    className,
    ...imageProps
}) => {
    const image = getImage(imageRaw);

    if (!image) return null;

    return (
        <GatsbyImage
            image={image}
            style={imageStyle}
            alt={alt || DEFAULT_ALT}
            className={className}
            {...imageProps}
        />
    );
};
