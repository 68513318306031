import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

export interface Fluid {
    aspectRatio: number;
    base64: string;
    sizes: string;
    src: string;
    srcSet: string;
}
export interface SharpImage {
    localFile?: IGatsbyImageData;
    alt_text: string;
}

export interface CourseModel {
    title: string;
    subtitle?: string;
    description: string;
    target: string;
    image?: SharpImage;
    start_date: string;
    url: string;
}

export interface SuccessStoryItemModel {
    name: string;
    photo?: SharpImage;
    jobtitle: string;
    short_feedback: string;
    contacts: string;
    feedback: string;
    slug: string;
    success_full_photo: SharpImage;
}

export interface PostStickyModel {
    slug: string;
    date: string;
    title: string;
    featured_media: { node: SharpImage };
}

export interface PostModel extends PostStickyModel {
    excerpt: string;
}

export interface PaginationList<T> {
    items: T[];
    lastPage: number;
}

export interface ImageSizeApi {
    file: string;
    height: number;
    mime_type: string;
    source_url: string;
    width: number;
}

export interface PostResponse {
    slug: string;
    status: string;
    type: string;
    link: string;
    title: {
        rendered: string;
    };
    content: { rendered: string; protected: boolean };
    excerpt: { rendered: string; protected: boolean };
    author: number;
    featured_media: number;
    comment_status: string;
    ping_status: string;
    sticky: boolean;
    template: string;
    format: string;
    meta: any[];
    categories: number[];
    tags: string[];
    acf: any;
}

export interface ServiceSlides {
    link: string;
    title: string;
    image: SharpImage;
}

export interface ServiceCategories {
    name: string;
    id: number;
}
export interface ServiceItems {
    id: string;
    title: string;
    services_type: number[];
    acf: {
        services: ServiceSlides;
    };
}

export interface ServiceModel {
    category: string;
    items: ServiceSlides[];
}

export interface Seo {
    title: string;
    opengraphUrl: string;
    opengraphType: string;
    opengraphTitle: string;
    opengraphSiteName: string;
    opengraphPublisher: string;
    opengraphPublishedTime: string;
    opengraphModifiedTime: string;
    opengraphDescription: string;
    opengraphAuthor: string;
    opengraphImage: SharpImage;
    metaKeywords: string;
    metaDesc: string;
}

export interface CalendarEventsModel {
    event_type: string;
    start_date: string;
    title: string;
    link: string;
}

export interface MarketModel {
    link: string;
    title: string;
    short_description: string;
    price: string;
    image: SharpImage;
    button_text: string;
}

export enum AvailableSections {
    courses = 'courses',
    successStrories = 'successStrories',
    services = 'services',
    actionBlockMiddle = 'actionBlockMiddle',
    market = 'market',
    calendarEvents = 'calendarEvents',
    articles = 'articles',
    actionBlockBottom = 'actionBlockBottom',
    form = 'form',
}

export enum FreeProgramType {
    masterClass = 'masterClass',
    test = 'test',
    vebinar = 'vebinar',
    materials = 'materials',
}

export interface FreeProgramModel {
    type: {
        label: string;
        value: FreeProgramType;
    };
    title: string;
    subTitle: string;
    freeProgramImage: SharpImage;
    description: string;
    link: string;
    data: string;
}
export interface FreeMasterClassModel {
    type: string;
    title: string;
    subTitle: string;
    freeMasterclassImage: SharpImage;
    description: string;
    link: string;
    date: string;
    date_text: string;
    button_text: string;
    button_description: string;
}

export interface MenuItemModel {
    url?: string;
    title: string;
    child_items?: MenuItemModel[];
}

export interface TestTypeResult {
    type: number;
    title: string;
    frequency: number;
    image: string | null;
}

export enum TestTypes {
    sum = 'sum',
    type = 'type',
    percent = 'percent',
}

export enum ResultView {
    circles = 'circles',
    images = 'images',
}

export interface TestTypeDescription {
    type: number;
    title: string;
    image: string | null;
}

export enum WPItemType {
    post = 'post',
}

export enum WPItemSubtype {
    post = 'post',
    page = 'page',
    internal_tests = 'internal_tests',
    success_stories = 'success_stories',
    courses = 'courses',
    free_masterclass = 'free_masterclass',
    free_programs = 'free_programs',
}

export interface WPSearchItem {
    id: number;
    title: string;
    url: string;
    type: WPItemType;
    subtype: WPItemSubtype;
    _links: {
        self: [
            {
                embeddable: boolean;
                href: string;
            }
        ];
        about: [
            {
                href: string;
            }
        ];
        collection: [
            {
                href: string;
            }
        ];
    };
}

export interface WPBasicItem {
    id: number;
    date: string;
    date_gmt: string;
    guid: {
        rendered: string;
    };
    modified: string;
    modified_gmt: string;
    slug: string;
    status: string;
    type: WPItemSubtype;
    link: string;
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
        protected: boolean;
    };
    excerpt: {
        rendered: string;
        protected: boolean;
    };
    author: number;
    featured_media: number;
    comment_status: string;
    ping_status: string;
    sticky: false;
    template: string;
    format: string;
    meta: any[];
    categories: number[];
    tags: number[];
}
