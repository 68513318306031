import Axios from 'axios';

export const WP_URL_ADMIN = 'https://wp.nemkova.pro';
export const SITE_URL = 'https://nemkova.pro';
export const WP_API_URL = `${WP_URL_ADMIN}/wp-json/wp/v2`;

export const WP_FORM_API_CONSULT = `${WP_URL_ADMIN}/wp-json/contact-form-7/v1/contact-forms/1385/feedback`;
export const WP_FORM_API_FEEDBACK = `${WP_URL_ADMIN}/wp-json/contact-form-7/v1/contact-forms/1536/feedback`;
export const WP_FORM_API_EXPERT_MILLION = `${WP_URL_ADMIN}/wp-json/contact-form-7/v1/contact-forms/1576/feedback`;

export const COMPANY_NAME = 'ЦЕНТР ОБРАЗОВАТЕЛЬНЫХ ТРАЕКТОРИЙ Евгении Немковой';

export const CONTENT_WIDTH = 1100;

export const isProdBuild = process.env.GATSBY_ENV === 'prod';

export type HeaderBgColor = 'sand';

export const BG_COLORS: { [key in HeaderBgColor]: string } = {
    sand: '#F7F0ED',
};

export const DEFAULT_ALT = 'Обучение коучингу';

export const POLICY_LINK =
    '/politika-konfidenczialnosti-obrabotki-i-zashhity-personalnyh-dannyh';
export const PUBLIC_AGREEMENT = '/publichnyj-dogovor';

export const ROUTES = {
    istoriiUspeha: 'istorii-uspeha',
    articles: 'articles',
    siteMap: 'karta-saita',
    payment: 'oplata-dostavka-i-vozvrat',
    freeProgramms: 'besplatnye-programmy',
    obuchenie: 'obuchenie',
    faq: 'faq',
};

export const SCRIPT_ADTHIS_PATH =
    '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f6aebf21d6b1a0d';

export const SCRIPT_JIVOSITE = '//code.jivosite.com/widget/mZwiqy4Fem';

export const COLORS = {
    red: '#a60424',
    white: '#fff',
    gray: '#ccc',
    darkGray: '#616161',
    black: '#000',
    lightGray: '#e8e8e8',
    lightGray2: '#ececec',
    isabelline: '#F5F0ED',
};

export const LAYOUT_WIDTH = 1124;

export const defaultSWRConfigs = {
    errorRetryInterval: 3000,
    revalidateOnFocus: false,
    fetcher: async (url: string) => {
        const res = await Axios.get(url);

        return res.data;
    },
    dedupingInterval: 999999,
};
