import jump from 'jump.js';
import { format, parse } from 'date-fns';
import locale from 'date-fns/locale/ru';
import { getImage } from 'gatsby-plugin-image';

import { MenuItemModel, SharpImage } from '../models';
import { SITE_URL } from '../constants';

export const scrollToBlock = (
    selector,
    opt?: { duration?: number; offset?: number; callback?(): void }
) => jump(selector, opt);

export const formatTextAriaString = (a: string) =>
    a?.replace(/\r?\n/g, '<br />');

export const formatMdText = (a: string) => a?.replace(/\r?\n/g, '<br /><br />');

export const prepareHtml = (html: string) => html.replace('/n', '');

export function getCoords(
    elem?: HTMLElement
): {
    top: number;
    left: number;
    height: number;
    width: number;
} {
    const box = elem?.getBoundingClientRect();

    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset,
        height: box.height,
        width: box.width,
    };
}

export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
}

const isExternalLink = (link: string) =>
    link?.startsWith('http://') || link?.startsWith('https://');

export const getUrl = (item: MenuItemModel) => ({
    url: item.url,
    isExternal: isExternalLink(item.url),
});

export const compareDatesAsc = (a = '', b = '') => {
    const [aDay, aMoth, aYear] = a.split(' ');
    const [bDay, bMoth, bYear] = b.split(' ');

    if (aYear > bYear) {
        return 1;
    }
    if (aYear === bYear) {
        if (aMoth > bMoth) {
            return 1;
        }
        if (aMoth === bMoth) {
            if (aDay > bDay) {
                return 1;
            }
            if (aDay === bDay) {
                return 0;
            }
        }
    }

    return -1;
};

export const isWebpSupported = () => {
    if (window) {
        let supportWebp = window?.supportWebp;
        if (typeof supportWebp !== 'undefined') {
            return supportWebp;
        }
        const elem = document?.createElement('canvas');
        supportWebp =
            elem?.toDataURL('image/webp')?.indexOf('data:image/webp') === 0;
        window.supportWebp = supportWebp;

        return supportWebp;
    }
};

export const isIframe = () => {
    if (window) {
        return window !== window.parent;
    }

    return false;
};

export const getWebpImageUrl = (url: string) => {
    const support = isWebpSupported();

    return support
        ? `${url?.replace('uploads', 'uploads-webpc/uploads')}.webp`
        : url;
};

export const applyYM = () => {
    const w = window as any;
    (function(m, e, t, r, i, k, a) {
        m[i] =
            m[i] ||
            function() {
                (m[i].a = m[i].a || []).push(arguments);
            };
        m[i].l = 1 * (new Date() as any);
        (k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            (k.async = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a);
    })(w, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    w?.ym(62749984, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    });
};

export function decodeHtml(html) {
    if (document) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;

        return txt.value;
    }
}

export const parseHeaders = (html: string) => {
    const regex = new RegExp('<h(1|2|3|4|5|6)>(.*?)</h(1|2|3|4|5|6)>', 'g');
    const arr = Array.from(html.matchAll(regex));

    let prevPosition = 0;
    let prevParents = [];

    const updArr = arr.map((item, i) => {
        const [, positionString = '0', title = ''] = item;

        const position = parseInt(positionString, 10);

        if (prevPosition !== position) {
            prevParents = prevParents.map((x, i) => (i > position ? 0 : x));
        }

        prevPosition = position;

        prevParents[position] = (prevParents[position] || 0) + 1;

        const regexTags = /(<([^>]+)>)/gi;

        return {
            position,
            title: title.replace(regexTags, ''),
            prefix: prevParents.filter((x) => Boolean(x)).join('.'),
        };
    });

    return updArr;
};

export const findElementByText = (tagName, text) => {
    const elements = document.getElementsByTagName(tagName);

    return Array.from(elements).find((el) => el.textContent === text);
};

export const parseWpDate = (date: string) => {
    try {
        return parse(date, 'yyyy-MM-dd', new Date());
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);

        return date;
    }
};

export const formatDate = (date: Date | string, formatString: string) => {
    if (typeof date === 'string') return date;

    try {
        return format(date, formatString, { locale });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);

        return date.toLocaleDateString();
    }
};

export const formatWpDate = (date: string, formatString: string) => {
    return formatDate(parseWpDate(date), formatString);
};

export const getAbsoluteImageUrl = (image?: SharpImage) => {
    return image
        ? `${SITE_URL}${image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`
        : undefined;
};
