import { graphql, useStaticQuery } from 'gatsby';

export const useGeneralSettings = (): {
    description: string;
    title: string;
} => {
    const { wp } = useStaticQuery(
        graphql`
            {
                wp {
                    generalSettings {
                        description
                        title
                    }
                }
            }
        `
    );

    return wp.generalSettings;
};
