import React from 'react';

interface AppContext {
    isMobileMenuOpened: boolean;
    toggleMobileMenu: () => void;
}

export const AppContext = React.createContext<AppContext>({
    isMobileMenuOpened: false,
    toggleMobileMenu: () => {},
});

export const AppContextProvider: React.FC<AppContext> = ({
    children,
    ...props
}) => (
    <AppContext.Provider value={{ ...props }}>{children}</AppContext.Provider>
);
