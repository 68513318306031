import React, { useState, CSSProperties } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

import { FONT_GOTHAM_LIGHT } from '../../styles/fonts';
import { getUrl } from '../../utils';
import { MenuItemModel } from '../../models';

const activeStylesMenuItem: CSSProperties = {
    color: '#A60424',
    pointerEvents: 'none',
};

const MobileMenuItem: React.FC<{
    item: MenuItemModel;
}> = ({ item }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <MenuWrap>
            <MenuItem
                to={getUrl(item).url}
                {...(getUrl(item).isExternal
                    ? {
                          href: getUrl(item).url,
                          target: '_blank',
                          rel: 'noopener',
                      }
                    : {
                          to: getUrl(item).url,
                          activeStyle: activeStylesMenuItem,
                          partiallyActive: false,
                          as: Link,
                      })}
            >
                {item.title}
            </MenuItem>
            {item.child_items?.length && (
                <>
                    <ToggleButton
                        isOpened={isOpened}
                        onClick={() => setIsOpened(!isOpened)}
                    />
                    <MenuSubItemContainer isOpened={isOpened}>
                        {item.child_items.map((subItem, index) => (
                            <MenuItem
                                key={index}
                                {...(getUrl(subItem).isExternal
                                    ? {
                                          href: getUrl(subItem).url,
                                          target: '_blank',
                                          rel: 'noopener',
                                      }
                                    : {
                                          to: getUrl(subItem).url,
                                          activeStyle: activeStylesMenuItem,
                                          partiallyActive: false,
                                          as: Link,
                                      })}
                            >
                                {subItem.title}
                            </MenuItem>
                        ))}
                    </MenuSubItemContainer>
                </>
            )}
        </MenuWrap>
    );
};

const ToggleButton = styled('button')<{ isOpened: boolean }>`
    position: absolute;
    right: 20px;
    top: 0;
    width: 40px;
    height: 40px;
    transform: ${({ isOpened }) =>
        !isOpened ? 'rotate(-90deg)' : 'rotate(90deg)'};
    transition: all 0.2s ease-in-out;

    border: none;
    outline: none;
    background: none;

    &:before {
        content: '\\2039';
        position: absolute;
        font-size: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const MenuWrap = styled.div`
    position: relative;
`;

const MenuSubItemContainer = styled('div')<{ isOpened: boolean }>`
    background-color: #f7f0ed;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    ${({ isOpened }) =>
        isOpened &&
        css`
            padding: 24px;
            max-height: 999px;
        `}
`;

const MenuItem = styled('a')`
    display: flex;
    width: 100%;
    padding: 14px 24px 14px 36px;
    color: #000000;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0);
    font-family: ${FONT_GOTHAM_LIGHT};
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #a60424;
    }
`;

export default MobileMenuItem;
