import React from 'react';
import styled from '@emotion/styled';

import devider from '../images/devider.png';
import { DEFAULT_ALT } from '../constants';

const Devider = () => {
    return <Img src={devider} alt={DEFAULT_ALT} />;
};

export default Devider;

const Img = styled.img`
    width: 286px;
`;
