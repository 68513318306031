import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';

import { COLORS, WP_API_URL } from '../../constants';
import useDebounce from '../../hooks/useDebounce';
import closeIcon from '../../images/close_cross.svg';
import { Box } from '../Box';
import { CustomScrollBar } from '../commonStyled';
import LoadingBlocker from '../LoadingBlocker';
import { Modal } from '../Modal';
import searchImage from '../../images/search_notfound.svg';
import { WPItemSubtype, WPSearchItem } from '../../models';
import { useFetch } from '../../hooks/useFetch';

import SearchItem from './SearchItem';
import SearchBox from './SearchBox';

const Container = styled(Box)`
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 33px;
    padding: 48px 0px;
    height: 100%;
`;

const CloseIcon = styled.button`
    position: absolute;
    right: 24px;
    top: 24px;
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    padding: 2px;
    background: url(${closeIcon}) no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    transition: all 0.2s;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        border: none;
        outline: none;
        opacity: 1;
    }
`;

const Shadow = css`
    content: '';
    z-index: 10;
    position: absolute;
    bottom: -1px;
    left: 10px;
    right: 10px;
    height: 18px;
    pointer-events: none;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.76) 76.08%,
        rgba(255, 255, 255, 0.89) 88.6%,
        #ffffff 100%
    );
`;

const Wrap = styled.div`
    height: 100%;
    position: relative;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
        ${Shadow}
    }

    &:before {
        ${Shadow}
        top: -2px;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.76) 76.08%,
            rgba(255, 255, 255, 0.89) 88.6%,
            #ffffff 100%
        );
    }
`;

const ItemsWrap = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    ${CustomScrollBar}
`;

const SearchImage = styled.img`
    width: 50%;
    height: auto;
`;
interface SearchProps {
    isOpen: boolean;
    onClose?: () => void;
}

const Search: React.FC<SearchProps> = ({ isOpen, onClose }) => {
    const [search, setSearch] = useState('');
    const inputRef = useRef(null);
    const debouncedSearch = useDebounce(search);

    const { data, isValidating } = useFetch<WPSearchItem[]>(
        debouncedSearch
            ? `${WP_API_URL}/search?search=${debouncedSearch}&per_page=7&subtype=${Object.keys(
                  WPItemSubtype
              ).join(',')}`
            : null
    );

    useEffect(() => {
        if (isOpen) {
            inputRef?.current?.focus();
        }
    }, [inputRef?.current, isOpen]);

    const noResults = <SearchImage src={searchImage} />;

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            modalHeight={823}
            modalWidth={750}
            closeOnClickOutside={false}
        >
            <Container minHeight={500}>
                <Box mx={32}>
                    <SearchBox
                        ref={inputRef}
                        value={search}
                        onValueChange={setSearch}
                    />
                </Box>
                <Wrap>
                    {Boolean(data?.length) && debouncedSearch && (
                        <ItemsWrap>
                            {data?.map((item) => (
                                <Box mx={32} key={item.id}>
                                    <SearchItem
                                        onItemClick={onClose}
                                        search={search}
                                        item={item}
                                    />
                                </Box>
                            ))}
                        </ItemsWrap>
                    )}
                    {isValidating && <LoadingBlocker isLoading />}
                    {!debouncedSearch && noResults}
                    {!data?.length && !isValidating && debouncedSearch && (
                        <>
                            <Box
                                mb="24px"
                                fontSize={18}
                                color={COLORS.darkGray}
                            >
                                По данному запросу ничего не найдено...
                            </Box>
                            {noResults}
                        </>
                    )}
                </Wrap>
                <CloseIcon onClick={onClose} />
            </Container>
        </Modal>
    );
};

export default Search;
