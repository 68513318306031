import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { FONT_GOTHAM_LIGHT } from '../styles/fonts';

export const TextInputStyles = css`
    width: 100%;
    padding: 16px 38px;
    margin-bottom: 14px;
    border-radius: 33px;
    background: #f7f0ed;
    box-shadow: inset -1px 3px 5px 0px rgba(0, 0, 0, 0.14);
    font-family: ${FONT_GOTHAM_LIGHT};
    outline: none;
    border: none;
    color: #2e0f25;
    font-size: 18px;
    line-height: 30px;

    &::placeholder {
        color: #b9b3b7;
        font-size: 18px;
        line-height: 30px;
    }
`;

export const Input = styled('input')<{ isError?: boolean }>`
    ${TextInputStyles}
    ${(props) =>
        props.isError &&
        css`
            border: 1px solid #a60424;
        `};
`;
