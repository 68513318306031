import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import logoPng from '../images/logo_bottom.png';
import logoWebp from '../images/logo_bottom.webp';
import linePng from '../images/bottom_line.png';
import lineWebp from '../images/bottom_line.webp';
import instagramPng from '../images/instagram.png';
import instagramWebp from '../images/instagram.webp';
import linkedInPng from '../images/linkedin.png';
import linkedInWebp from '../images/linkedin.webp';
import { FONT_GOTHAM_LIGHT } from '../styles/fonts';
import {
    POLICY_LINK,
    DEFAULT_ALT,
    ROUTES,
    PUBLIC_AGREEMENT,
    LAYOUT_WIDTH,
} from '../constants';
import { useWebpImage } from '../hooks/useWebpImage';
import { useMainPageData } from '../queries/useMainPageData';

import { CompatibleImg } from './Img';

const LinkStyled = styled(Link)`
    display: block;
`;

const Footer = () => {
    const logo = useWebpImage(logoWebp, logoPng);
    const line = useWebpImage(lineWebp, linePng);
    const instagram = useWebpImage(instagramWebp, instagramPng);
    const linkedIn = useWebpImage(linkedInWebp, linkedInPng);
    const { footer, footerImage } = useMainPageData();

    return (
        <Container>
            <Logo src={logo} alt={DEFAULT_ALT} />
            <Socials>
                <SocialLink
                    target="_blank"
                    rel="nofollow"
                    href="https://www.instagram.com/nemkova.evgeniya"
                >
                    <SocialImg src={instagram} alt={DEFAULT_ALT} />
                </SocialLink>
                <SocialLink
                    target="_blank"
                    rel="nofollow"
                    href="https://by.linkedin.com/in/evgeniya-nemkova-772535101?trk=profile-badge"
                >
                    <SocialImg src={linkedIn} alt={DEFAULT_ALT} />
                </SocialLink>
            </Socials>
            <Text itemScope itemType="http://schema.org/Organization">
                <div
                    dangerouslySetInnerHTML={{
                        __html: footer.replace(
                            'YEAR',
                            `${new Date().getFullYear()}`
                        ),
                    }}
                />
                <LinkStyled to={POLICY_LINK}>
                    Политика конфиденциальности
                </LinkStyled>
                <LinkStyled to={PUBLIC_AGREEMENT}>Публичный договор</LinkStyled>
                <LinkStyled to={`/${ROUTES.payment}`}>
                    Оплата, доставка и возврат
                </LinkStyled>
                <LinkSiteMap to={`/${ROUTES.faq}`}>
                    Вопросы и ответы
                </LinkSiteMap>
                <LinkSiteMap to={`/${ROUTES.siteMap}`}>Карта сайта</LinkSiteMap>
            </Text>
            <CompatibleImg image={footerImage?.localFile} alt={DEFAULT_ALT} />
            <LineBottom src={line} alt={DEFAULT_ALT} />
        </Container>
    );
};

export default Footer;

const LinkSiteMap = styled(Link)`
    display: block;
    color: #696969;
`;

const Socials = styled.div`
    display: flex;
`;

const Text = styled.div`
    color: #696969;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 12px;
`;

const LineBottom = styled.img`
    width: 110px;
    margin-top: 29px;
`;

const Logo = styled.img`
    width: 172px;
    margin-bottom: 37px;
`;

const SocialLink = styled.a`
    display: block;

    &:not(:last-child) {
        margin-right: 24px;
    }
`;

const SocialImg = styled.img`
    width: 64px;
    margin-bottom: 21px;
`;

const Container = styled.footer`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: ${LAYOUT_WIDTH}px;
    width: 100%;
`;
