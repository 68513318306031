import { useRef } from 'react';
import useSWR from 'swr';

export const useFetch = <T>(url: string | null) => {
    const prevData = useRef(null);
    const { data, error, isValidating, mutate } = useSWR<T>(url);

    if (data) prevData.current = data;

    return {
        error,
        isValidating,
        mutate,
        data: prevData.current as T | null,
    };
};
