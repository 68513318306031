import { useEffect, useState } from 'react';

import { isIframe } from '../utils';

export const useExternalScript = (
    name: string,
    src: string,
    cb?: () => void
) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const event = isIframe() ? 'load' : 'scroll';
        const handler = () => {
            if (isLoaded || window?.[`${name}-script`]) {
                if (window?.[`${name}-script`] && !isLoaded) {
                    setIsLoaded(true);
                }

                return;
            }

            if (cb) cb();

            if (src) {
                const scriptEl = document.createElement('script');
                scriptEl.src = src;
                scriptEl.async = true;
                const scriptHeader = document.getElementsByTagName('script')[0];
                scriptHeader.parentNode.insertBefore(scriptEl, scriptHeader);
            }

            setIsLoaded(true);
            window[`${name}-script`] = true;

            window?.removeEventListener(event, handler);
        };

        window?.addEventListener(event, handler, {
            passive: true,
        });
    }, []);

    return isLoaded;
};
