import React from 'react';
import styled from '@emotion/styled';

import { FONT_GOTHAM_LIGHT } from '../styles/fonts';

import { device } from './commonStyled';

const SectionTitle: React.FC<{
    title: string;
    fontSize?: number;
    className?: string;
    isMain?: boolean;
}> = ({ title, fontSize, className, isMain }) => {
    return (
        <Container
            className={className}
            fontSizeProp={fontSize}
            dangerouslySetInnerHTML={{ __html: title }}
            as={isMain ? 'h1' : 'div'}
        />
    );
};

const Container = styled('div')<{ fontSizeProp?: number }>`
    color: #2e0f25;
    font-size: ${({ fontSizeProp }) => fontSizeProp || 48}px;
    margin-bottom: 60px;
    font-family: ${FONT_GOTHAM_LIGHT};

    @media ${device.mobileMedium} {
        margin-bottom: 12px;
        font-size: 34px;
        width: 100%;
        text-align: center;
    }
`;

export default SectionTitle;
