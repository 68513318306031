import styled from '@emotion/styled';
import React, { forwardRef, useEffect } from 'react';
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition';
import { css, keyframes } from '@emotion/react';

import { Box, Flex } from '../Box';
import { ReactComponent as MicrophoneIcon } from '../../images/microphone-icon.svg';
import { ReactComponent as StopIcon } from '../../images/stop-button-icon.svg';
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg';
import { Input } from '../Input';
import { COLORS } from '../../constants';

interface SearchProps {
    value: string;
    onValueChange(val: string): void;
}

const MicrophoneButton = styled(MicrophoneIcon)<{ isActive: boolean }>`
    fill: ${COLORS.darkGray};
    width: 25px;
    height: 25px;
    flex-shrink: 0;

    ${({ isActive }) =>
        isActive &&
        css`
            animation: ${fadeIn} 0.8s ease-in infinite;
        `}
`;

const MicrophoneStop = styled(StopIcon)`
    fill: ${COLORS.darkGray};
    width: 25px;
    height: 25px;
    flex-shrink: 0;
`;

export const fadeIn = keyframes`
    0% {opacity: 1;}
   50% {opacity: 0.5;}
   100% {opacity: 1;}
`;

const ClearSearch = styled(CloseIcon)`
    fill: ${COLORS.darkGray};
    flex-shrink: 0;
`;

const InputStyled = styled(Input)`
    margin-bottom: 0;
`;

const BoxStyled = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`;

const isSpeechRecognitionSupported = SpeechRecognition.browserSupportsSpeechRecognition();

const SearchBox = forwardRef<HTMLInputElement, SearchProps>(
    ({ value, onValueChange }, ref) => {
        const {
            transcript,
            resetTranscript,
            listening,
        } = useSpeechRecognition();

        console.log(transcript);

        useEffect(() => {
            if (transcript) {
                onValueChange(transcript);
            }
        }, [transcript]);

        return (
            <Box position="relative" mb="14px">
                <InputStyled
                    ref={ref}
                    placeholder="Введите текст для поиска"
                    value={value}
                    onChange={(e) => onValueChange(e.target.value)}
                    type="text"
                />

                <Flex
                    alignItems="center"
                    position="absolute"
                    top="0px"
                    right="15px"
                    height="100%"
                >
                    {value && (
                        <BoxStyled
                            title="Очистить поле ввода"
                            padding="5px"
                            onClick={() => {
                                onValueChange('');
                            }}
                        >
                            <ClearSearch />
                        </BoxStyled>
                    )}
                    {isSpeechRecognitionSupported && (
                        <>
                            {listening && (
                                <BoxStyled
                                    title="Остановить запись"
                                    padding="5px"
                                    onClick={() => {
                                        SpeechRecognition.stopListening();
                                        resetTranscript();
                                    }}
                                >
                                    <MicrophoneStop />
                                </BoxStyled>
                            )}
                            <BoxStyled
                                title="Голосовой поиск"
                                padding="5px"
                                onClick={() =>
                                    SpeechRecognition.startListening({
                                        language: 'ru',
                                    })
                                }
                            >
                                <MicrophoneButton isActive={listening} />
                            </BoxStyled>
                        </>
                    )}
                </Flex>
            </Box>
        );
    }
);

export default SearchBox;
