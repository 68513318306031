import styled from '@emotion/styled';
import Axios from 'axios';
import { Link } from 'gatsby';
import React, { useMemo } from 'react';
import useSWR from 'swr';

import { COLORS, ROUTES, WP_URL_ADMIN } from '../../constants';
import {
    WPBasicItem,
    WPItemSubtype,
    WPSearchItem as ISearchItem,
} from '../../models';
import { FONT_GOTHAM_LIGHT, FONT_GOTHAM_MEDIUM } from '../../styles/fonts';

const Container = styled(Link)`
    display: block;
    padding: 24px 16px;
    background: ${COLORS.isabelline};
    border-radius: 15px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-family: ${FONT_GOTHAM_MEDIUM};
    font-size: 16px;
    margin-bottom: 8px;
`;

const Description = styled.div`
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 14px;
    min-height: 40px;
`;

const parseString = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    return doc.body.textContent || null;
};

const trimString = (str?: string, search?: string, length = 100) => {
    if (!str || !search) return '';

    const indexSearched = str?.toLowerCase().indexOf(search?.toLowerCase());

    if (indexSearched > -1) {
        const SHIFT = 20;
        const shiftedIndex = indexSearched - SHIFT;
        const findIndex = shiftedIndex > 0 ? shiftedIndex : 0;
        const resultString = str?.substring(findIndex, findIndex + length);
        const highlightedText = resultString.replace(
            new RegExp(`(^|\\s)(${search})(\\s|.|$)`, 'ig'),
            '$1<b>$2</b>$3'
        );

        return `...${highlightedText}...`;
    }

    return `${str?.substring(0, length)}...`;
};

const getUrlMap: (
    slug: string
) => Record<keyof typeof WPItemSubtype, string> = (slug) => ({
    [WPItemSubtype.post]: `/${ROUTES.articles}${slug}`,
    [WPItemSubtype.page]: slug,
    [WPItemSubtype.courses]: `/${ROUTES.obuchenie}`,
    [WPItemSubtype.free_masterclass]: `/${ROUTES.freeProgramms}`,
    [WPItemSubtype.free_programs]: `/${ROUTES.freeProgramms}`,
    [WPItemSubtype.internal_tests]: `${slug.replace(
        `/${WPItemSubtype.internal_tests}`,
        ''
    )}`,
    [WPItemSubtype.success_stories]: `/${ROUTES.istoriiUspeha}`,
});

const getUrl = (url = '', type: WPItemSubtype) => {
    const slug = url.replace(WP_URL_ADMIN, '');

    return getUrlMap(slug)[type] || url;
};

interface SearchProps {
    item: ISearchItem;
    search: string;
    onItemClick(): void;
}

const SEARCH_MAX_SYMBOLS = 150;

const SearchItem: React.FC<SearchProps> = ({ item, search, onItemClick }) => {
    const url = item?._links?.self?.[0]?.href;
    const { data: itemDescription } = useSWR<WPBasicItem>(url);

    const description = useMemo(() => {
        if (!itemDescription) return null;

        const parsedString = parseString(itemDescription?.content?.rendered);

        return trimString(parsedString, search, SEARCH_MAX_SYMBOLS);
    }, [itemDescription, search]);

    return (
        <Container to={getUrl(item?.url, item.subtype)} onClick={onItemClick}>
            <Title dangerouslySetInnerHTML={{ __html: item.title }} />
            <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Container>
    );
};

export default SearchItem;
