import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface BurgerIconProps {
    isOpened: boolean;
    toggleMenu: () => void;
    className?: string;
}

export const BurgerIcon: React.FC<BurgerIconProps> = ({
    isOpened,
    toggleMenu,
    className,
}) => (
    <BurgerContainer
        isOpened={isOpened}
        onClick={toggleMenu}
        className={className}
    >
        <LineTop />
        <LineMiddle />
        <LineBottom />
    </BurgerContainer>
);

const Line = styled.div`
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #6e5f67;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
`;

const LineTop = styled(Line)``;

const LineMiddle = styled(Line)`
    transform-origin: 0% 0%;
`;

const LineBottom = styled(Line)`
    transform-origin: 0% 100%;
`;

const BurgerContainer = styled('div')<{ isOpened: boolean }>`
    position: relative;
    z-index: 1001;
    width: 40px;
    height: 22px;

    margin-right: 20px;
    margin-left: 20px;

    ${({ isOpened }) =>
        isOpened &&
        css`
            ${LineTop}, ${LineMiddle},${LineBottom} {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: #6e5f67;
            }

            ${LineMiddle} {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            ${LineBottom} {
                transform: rotate(-45deg) translate(0, -1px);
            }
        `}
`;
