import { graphql, useStaticQuery } from 'gatsby';

import { MenuItemModel } from '../models';
import { WP_URL_ADMIN } from '../constants';

interface MenuWp {
    parentId: string | null;
    path: string;
    url: string;
    label: string;
    id: string;
}

export const useMenu = (): MenuItemModel[] => {
    const { allWpMenu } = useStaticQuery(
        graphql`
            {
                allWpMenu {
                    nodes {
                        menuItems {
                            nodes {
                                parentId
                                path
                                label
                                url
                                id
                            }
                        }
                    }
                }
            }
        `
    );

    const menusRaw: MenuWp[] = allWpMenu?.nodes[0]?.menuItems?.nodes;

    const getChildren = (id: string): MenuItemModel[] | undefined => {
        const items: MenuItemModel[] = menusRaw
            .filter((x) => x.parentId === id)
            .map((x) => ({
                title: x.label,
                url: x.url.includes(WP_URL_ADMIN) ? x.path : x.url,
            }));

        return items.length ? items : undefined;
    };

    return menusRaw
        .filter((item) => !item.parentId)
        .map((item) => ({
            title: item.label,
            url: item.path,
            child_items: getChildren(item.id),
        }));
};
