import React, { KeyboardEventHandler } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Portal } from 'react-portal';

interface ModalProps {
    isOpen: boolean;
    closeOnClickOutside?: boolean;
    onClose?: () => void;
    modalWidth?: number;
    modalHeight?: number;
    children: React.ReactNode;
}

const modalRef = React.createRef<HTMLDivElement>();

export const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    children,
    modalWidth,
    modalHeight,
    closeOnClickOutside = true,
}) => {
    const closeModal = (e) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(e.target) &&
            closeOnClickOutside
        ) {
            onClose();
        }
    };

    const onKeyDown: KeyboardEventHandler = (e) => {
        if (e.key === 'Escape') onClose();
    };

    return (
        isOpen && (
            <Portal>
                <ModalBlocker onKeyDown={onKeyDown} onClick={closeModal}>
                    <ModalWindow
                        modalWidth={modalWidth}
                        modalHeight={modalHeight}
                        ref={modalRef}
                    >
                        {children}
                    </ModalWindow>
                </ModalBlocker>
            </Portal>
        )
    );
};

export const fadeIn = keyframes`
    0% {opacity: 0;}
   100% {opacity: 1;}
`;

const ModalBlocker = styled.div`
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(48, 50, 56, 0.4);
    backdrop-filter: blur(1px);
    animation: ${fadeIn} 0.2s ease-in;
`;

const ModalWindow = styled('div')<{ modalWidth: number; modalHeight: number }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: ${({ modalWidth }) => modalWidth || 976}px;
    max-height: ${({ modalHeight }) => modalHeight || 976}px;

    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        max-height: 90%;
    }
`;
