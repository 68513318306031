import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { AppContext } from '../AppContext';
import { device } from '../commonStyled';
import { useMenu } from '../../queries/useMenu';

import MobileMenuItem from './MobileMenuItem';
import { BurgerIcon } from './BurgerIcon';

export const MobileMenu: React.FC = () => {
    const {
        isMobileMenuOpened: isOpened,
        toggleMobileMenu: toggleMenu,
    } = useContext(AppContext);

    const menus = useMenu();

    return (
        <>
            <MenuContainer isOpened={isOpened}>
                <BurgerIconStyled isOpened={isOpened} toggleMenu={toggleMenu} />
                {menus.map((item, i) => (
                    <MobileMenuItem item={item} key={i} />
                ))}
            </MenuContainer>
            <BgShadow isOpened={isOpened} onClick={toggleMenu} />
        </>
    );
};

const BurgerIconStyled = styled(BurgerIcon)`
    margin-bottom: 20px;
    margin-left: 33px;
`;

const BgShadow = styled('div')<{ isOpened: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    transition: all 0.3s ease-in-out;

    ${({ isOpened }) =>
        isOpened
            ? css`
                  visibility: visible;
                  opacity: 1;
                  background: rgba(0, 0, 0, 0.2);
              `
            : css`
                  background: rgba(0, 0, 0, 0);
                  visibility: hidden;
                  opacity: 0;
              `}
`;

const MenuContainer = styled('div')<{ isOpened: boolean }>`
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100%;
    height: auto;
    width: 100%;
    max-width: 500px;

    padding-top: 30px;
    padding-bottom: 24px;

    background: #ffffff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;

    transform-origin: 0% 0%;
    z-index: 10000;
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    transform: ${({ isOpened }) => (isOpened ? 'none' : 'translate(-100%, 0)')};

    @media ${device.laptopMedium} {
        ${({ isOpened }) =>
            isOpened
                ? css`
                      visibility: visible;
                      opacity: 1;
                  `
                : css`
                      opacity: 0;
                      visibility: hidden;
                      pointer-events: none;
                  `};
    }
`;
